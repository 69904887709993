/* Hero Section Styling */
.hero-section-container {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeInBackground 15s infinite; /* New animation for the fading background */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 3s ease-in-out;
}

.hero-section-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.2); */
  /* background-color: rgba(0, 0, 0, 0.5);  */
  /* background-color: rgba(255, 255, 255, 0.2);  */
  /* background-color: rgba(173, 216, 230, 0.3); */
  background-color: rgba(150, 120, 220, 0.2);
  z-index: 1; /* Ensures overlay is between background and content */
}

@keyframes fadeInBackground {
  0% { background-image: url('../../../asset/Images/banner/b3.jpg'); }
  33% { background-image: url('../../../asset/Images/banner/b2.jpg'); }
  66% { background-image: url('../../../asset/Images/banner/b1.png'); }
  100% { background-image: url('https://ml5enisp4q1t.i.optimole.com/cb:avo9~b5e4/w:auto/h:auto/q:mauto/ig:avif/f:best/https://interiorarchitects.com/wp-content/uploads/2020/01/Website-Case-Study-Template15-1.jpg'); } /* Loop back to first image */
}

.text-center-container {
  position: relative;
  z-index: 2; /* Content is placed above the overlay */
  text-align: center;
  max-width: 800px;
  padding: 0 20px;
}

/* Text Styles */
.hh {
  position: relative;
  color: white;
  /* color: #977EFE; */
  z-index: 2; /* Ensure text is on top of everything */
  transition: color 0.3s ease, transform 0.3s ease;
}
/* 
.hh::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: -1; 
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.3s ease;
} */

.hh:hover {
  color: #977EFE;
  transform: scale(1.05);
}

.hh:hover::before {
  background-color: rgba(0, 0, 0, 0.7);
  transform: scale(1.05);
}

/* Button Styling */
.btnn-link {
  color: white !important;
  background: linear-gradient(90deg, #941BFE, #977EFE);
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btnn-link:hover {
  background: linear-gradient(90deg, rgba(0, 78, 255, 1) 0%, rgba(0, 123, 255, 1) 100%);
  text-decoration: none;
}
