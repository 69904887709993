
/* 
android developement
web developement
digital marketing
Graphics design
E-commerce solution
Erp solution


*/



.navbar {
    background-color: #ffffff;
    padding: 15px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-bottom: 5px solid; /* Adjust thickness as needed */
    border-image: linear-gradient(90deg, #941BFE, #977EFE); /* The gradient you specified */
    border-image-slice: 1;
}

.navbarStyle {
    max-width: 1200px;
    margin: 0 auto;
}

.navbar-brand img {
    height: 50px;
    width: auto;
}

.navbar-toggler {
    border: none;
}

/* Default Navbar Link Styling */
.nav-link {
    font-size: 1rem;
    font-weight: 500;
    margin-right: 20px;
    color: #333;
    transition: color 0.3s ease, background 0.3s ease;
    padding: 5px 10px;
    border-radius: 30px;
}

/* Hover Effect for Links */
.nav-link:hover {
    color: #9420FD !important;
}

/* Active Route Styling */
.nav-link.active {
    background: linear-gradient(90deg, #941BFE, #977EFE); /* Gradient applied */
    -webkit-background-clip: text; /* Clip the gradient to the text */
    -webkit-text-fill-color: transparent; /* Make the text transparent to show the gradient */
    font-weight: 600; /* Optional: Make it bold for better visibility */
    border-radius: 30px;
    padding: 5px 15px;
    font-weight: bold !important;
    transition: background 0.3s ease, color 0.3s ease;
}


/* Contact Button Styling */
.buttonStyle {
    background: linear-gradient(90deg, #941BFE, #977EFE);
    color: white;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: background 0.3s ease, transform 0.3s ease;
}


.buttonStyle:hover {
    background: linear-gradient(90deg, #977EFE, #941BFE); /* Reverse gradient on hover */
    color: white;
    transform: translateY(-3px); /* Lift effect */
}

.buttonStyle:active {
    /* transform: translateY(0px); Reset position on active */
    background: linear-gradient(90deg, #8A18E0, #8F72DF); /* Slightly darker gradient for active state */
}







/* Navbar Collapse (Large Devices) */
.navbar-collapse {
    max-height: none; /* No height restriction */
    opacity: 1; /* Fully visible */
    overflow: visible;
    transition: none; /* No animation */
}

/* Navbar Collapse (Small Devices) */
@media (max-width: 991px) {
    .navbar-collapse {
        overflow: hidden;
        max-height: 0; /* Start with the menu hidden */
        opacity: 0;
        transition: max-height 1s ease, opacity 1s ease; /* Smooth animation */
    }

    .navbar-collapse.show-dropdown {
        max-height: 500px; /* Adjust based on your content's height */
        opacity: 1;
        padding-bottom: 10px;
    }

    .navbar-collapse.hide-dropdown {
        max-height: 0;
        opacity: 0;
    }

    /* Adjust links for small devices */
    .navbar-nav .nav-link {
        margin-right: 0;
        margin-bottom: 15px;
        font-size: 1.1rem;
        text-align: center;
    }

    .buttonStyle {
        width: 100%;
        text-align: center;
        padding: 10px;
        font-size: 1.1rem;
    }
}









/* Contact Button Always Visible */
.contact-btn {
    display: block; /* Always visible */
    text-align: center; /* Center-align on mobile */
    padding: 10px 15px;
}

@media (max-width: 576px) {
    .navbar-brand img {
        height: 40px;
    }

    .buttonStyle {
        padding: 8px 15px;
        font-size: 0.9rem;
    }
}










.nav-item.dropdown {
    position: relative;
  }
  
  .nav-link {
    color: #333;
    text-decoration: none;
    padding: 10px 20px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .nav-link.active-route {
    background: linear-gradient(90deg, #941BFE, #977EFE);
    color: #fff !important;
    border-radius: 5px;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 700px;
    border-radius: 8px;
    padding: 15px;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 9999;
  }
  

  .dropdown-menu.show {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }
  

  .dropdown-columns {
    display: flex;
    flex-wrap: wrap; 
    /* gap: 20px;  */
  }
  

  .dropdown-column {
    flex: 1 1 calc(50% - 20px); 
    padding: 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  
  .dropdown-column h4 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
  }
  
  .dropdown-item {
    display: block;
    padding: 8px 10px;
    color: #333;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .dropdown-item:hover {
    background: linear-gradient(90deg, #941BFE, #977EFE);
    color: #fff !important;
    transform: scale(1.05);
  }
  

  @media (max-width: 1024px) {
    .dropdown-columns {
      gap: 15px; 
    }
    .dropdown-column {
      flex: 1 1 calc(50% - 15px); 
    }
  }
  
  @media (max-width: 768px) {
    .dropdown-menu {
        
      min-width: 100%; 
      padding: 10px;
    }
  
    .dropdown-columns {
        text-align: center;
      flex-direction: column; 
      gap: 10px;
    }
  
    .dropdown-column {
      flex: 1 1 100%;
    }
  }
  