/* Banner Section */
.banner-section {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust as needed */
  background-image: url('https://www.vietnamworks.com/hrinsider/wp-content/uploads/2023/10/Back-Office-7.png'); /* Replace with your image */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Black Transparent Shade */
.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.711); /* Adjust transparency here */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Text Styling */
.banner-title {
  position: relative;
  color: white;
  font-size: 3rem; /* Adjust as needed */
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}






/* Contact Button Styling */
.buttonStyle {
  background: linear-gradient(90deg, #941BFE, #977EFE);
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: background 0.3s ease, transform 0.3s ease;
}


.buttonStyle:hover {
  background: linear-gradient(90deg, #977EFE, #941BFE); /* Reverse gradient on hover */
  color: white;
  transform: translateY(-3px); /* Lift effect */
}

.buttonStyle:active {
  transform: translateY(0px); /* Reset position on active */
  background: linear-gradient(90deg, #8A18E0, #8F72DF); /* Slightly darker gradient for active state */
}
