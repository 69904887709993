/* Basic styles for the dashboard container */
.dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full viewport height */
}

/* Flex layout for the sidebar and content */
.dashboard-content {
    display: flex;
    flex-grow: 1;
    overflow: hidden; 
}

/* Sidebar styles */
.sidebar-container {
    min-width: 250px;
    max-width: 250px;
    background-color: #f8f9fa;
    height: 100vh; /* Full height for sidebar */
    border-right: 1px solid #ddd; /* Add border to the right */
    position: fixed; /* Keeps the sidebar fixed on the left */
}

.sidebar-heading {
    color: #333;
    font-weight: bold;
    margin-bottom: 1rem;
}

.sidebar-link {
    color: #333;
    font-size: 16px;
    padding: 0.75rem;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.sidebar-link:hover {
    background-color: black;
    border-radius: 5px;
    text-decoration: none;
}

.active-link {
    background-color: #007bff;
    color: #fff !important;
    border-radius: 5px;
}

/* Main content area */
.content {
    margin-left: 250px; /* Matches the sidebar width */
    padding: 20px;
    overflow-y: auto;
    width: 100%;
    background-color: #f1f1f1;
}

/* Responsive tweaks */
@media (max-width: 768px) {
    .sidebar-container {
        position: relative;
        width: 100%;
        height: auto;
        max-width: none;
    }

    .content {
        margin-left: 0;
    }
}
