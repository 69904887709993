.technology-section {
  position: relative;
}

.technology-section h2 {
  font-size: 2rem;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
}

.technology-section p {
  font-size: 1rem;
}

.technology-carousel-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgb(229, 231, 235);
  padding: 1.5rem;
  border-radius: 10px;
  width: 100%;
  max-width: 70%;
  overflow: hidden;
  margin-bottom: 90px;
}

.technology-carousel {
  display: flex;
  gap: 1rem;
  animation: scroll 15s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}





/* Gradient Text for Heading */
.gradient-text {
  background: linear-gradient(90deg, #941BFE, #977EFE); /* Gradient colors */
  -webkit-background-clip: text; /* Apply gradient to text only */
  -webkit-text-fill-color: transparent; /* Make the text transparent */
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
  display: inline-block;
  animation: fadeIn 1.5s ease; /* Optional fade-in effect for the text */
}

/* After Line Under Title */
.section-title::after {
  content: '';
  display: block;
  width: 60px;
  height: 3px;
  background-color: #007bff;
  margin: 10px auto 0;
  border-radius: 5px;
  transition: width 0.3s ease;
}

/* Fade-in Animation for Gradient Text */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}




.technology-card {
  min-width: 100px;
  max-width: 200px;
  width: 100%;
  flex-shrink: 0;
  transition: transform 0.3s ease;
}

.technology-card:hover {
  transform: scale(1.05);
}


.button-style {
  background: linear-gradient(90deg, #941bfe, #977efe);
  color: white !important;
  outline: none;
  box-shadow: none; /* Ensures no default focus outline */
  border: none;
  padding: 10px 20px;
  border-radius: 50%; /* Rounded corners for a pill-shaped button */
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.3s ease;
}

.button-style:hover,
.button-style:focus {
  transform: scale(1.05); /* Slightly enlarge the button on hover */
  background: linear-gradient(90deg, #6d13d0, #825ee4); /* Slightly darker gradient */
  color: white;
  outline: none;
  box-shadow: none;
}




@media (max-width: 768px) {
  .technology-section h2 {
    font-size: 1.5rem;
  }

  .technology-carousel-wrapper {
    width: 100%;
  }

  .technology-card {
    min-width: 250px;
  }
}

@media (max-width: 576px) {
  .technology-section h2 {
    font-size: 1.3rem;
  }

  .technology-section p {
    font-size: 0.9rem;
  }

  .technology-carousel-wrapper {
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 90%;
    border-radius: 0;
  }
}
