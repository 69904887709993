.our-client-section {
    padding: 40px 20px;
    /* background-color: #f9f9f9; */
    text-align: center;
  }
  

  
   .section-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
    color: transparent; /* Set text color to transparent */
    background: linear-gradient(90deg, #941bfe, #977efe); /* Add linear gradient */
    -webkit-background-clip: text; /* Ensures the gradient is clipped to the text */
    -webkit-text-fill-color: transparent; /* Chrome-specific gradient text fix */
}

  
   /* Grid container for logos */
  .client-logos-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  /* Individual client card */
  .client-card {
    cursor: pointer;
    position: relative;
    height: 120px;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .client-card:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }
  

  .client-card:hover {
    box-shadow: 0 4px 8px #977efe;
    /* border: 2px solid transparent;  */
    background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, #941bfe, #977efe); /* Gradient border */
    background-origin: border-box; /* Ensures the gradient aligns with the border */
    background-clip: padding-box, border-box; /* Clips the inner background */
}


  /* Client logos */
  .client-logo {
    width: 100%;
    max-width: 120px;
    filter: grayscale(100%);
    transition: filter 0.3s ease, transform 0.3s ease;
  }
  
  .client-card:hover .client-logo {
    filter: grayscale(0%); /* Removes grayscale on hover */
    transform: scale(1.1); /* Optional: Slight zoom on hover */
  }
  