/* Ensure that the filled part of the progress bar is purple */
.progress-bar {
  background-color: #a97ff8 !important; /* Purple */
}

/* Set the progress bar's background to white */
.progress-bar-bg {
  background-color: #bebbbb !important; /* White background */
}

/* Banner Section */
.banner-section {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust as needed */
  background-image: url('https://www.vietnamworks.com/hrinsider/wp-content/uploads/2023/10/Back-Office-7.png'); /* Replace with your image */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Black Transparent Shade */
.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.711); /* Adjust transparency here */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Text Styling */
.banner-title {
  position: relative;
  color: white;
  font-size: 3rem; /* Adjust as needed */
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}


/* Image Grid Styling */
.image-grid {
  display: flex;
  gap: 1rem;
  position: relative;
 
}

.image-large {
  flex: 2;
}

.image-small-stack {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
 padding-top: 10%;

}

/* Ensure the large image matches the combined height of smaller images */
.fixed-height {
  height: 100%;
  object-fit: cover;
  max-height: 460px; /* Matches combined height of two smaller images */
}

.image-large img,
.image-small-stack img {
  width: 100%;
  border-radius: 0.5rem;
}

/* Badge Styling */
.years-badge {
  bottom: 10px;
  left: 10px;
  background: linear-gradient(90deg, #941BFE, #977EFE);;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  z-index: 2;
}

.badge-content h4 {
  margin: 0;
}

.badge-content p {
  margin: 0;
  font-size: 0.8rem;
}

/* Content Box */
.content-box {
  color: #000; /* Black text for contrast */
}

.purple-text {
  color: rgb(163, 62, 246); /* Apply purple to numbers */
}

/* Button Styling */
.btn-purple {
  border-radius: 0.5rem;
  background: linear-gradient(90deg, #941BFE, #977EFE); /* Use background, not background-color */
  color: white !important;
  transition: transform 0.3s, background 0.3s; /* Transition for hover effects */
}

.btn-purple:hover {
  transform: scale(1.05);
  background: linear-gradient(90deg, #8F5FEF , #7A0BCC); /* Darker gradient for hover */
}
