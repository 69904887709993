/* Footer Container */
.footer-container {
    background-color: #1a1a1a;
    color: #f3f3f3;
    padding: 2rem 0;
}

.footer_style {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Ensures even spacing between columns */
    padding: 2rem;
    gap: 2rem; /* Adds gap between the columns */
}

.footer-section {
    flex: 1 1 200px; /* Each section takes at least 200px, but will grow to fill the available space */
    max-width: 25%; /* Limits each section to take up at most 25% of the row on larger screens */
}

.footer-logo {
    width: 150px;
}

.footer-text {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: #bbb;
}

.footer-title {
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: 1rem;
}

.footer-link {
    display: block;
    color: #bbb;
    margin-bottom: 0.5rem;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-link:hover {
    color: #00DFA2;
}

.footer-contact {
    color: #bbb;
    margin-bottom: 1rem;
    font-size: 0.9rem;
}

.footer-socials {
    display: flex;
    gap: 1rem;
}

.social-icon {
    color: #fff;
    padding: 10px 15px;
    border-radius: 50%;
    background-color: #555;
    display: inline-block;
    transition: background-color 0.3s ease;
}

.social-icon.facebook {
    background-color: red;
}

.social-icon.linkedin {
    background-color: red;
}

.social-icon:hover {
    background-color: #333;
}

.footer-subscribe {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    flex-wrap: nowrap;
}

.subscribe-input {
    padding: 10px;
    border: none;
    border-radius: 5px;
    max-width: 250px;
    flex: 1;
}

.subscribe-button {
    padding: 10px 20px;
    background-color: #00DFA2;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
    flex-shrink: 0;
}

/* Small devices (max-width: 768px) */
@media (max-width: 768px) {
    .footer_style {
        flex-direction: column; /* Stacks sections vertically on smaller screens */
    }

    .footer-section {
        max-width: 100%; /* Each section takes full width on small screens */
    }

    .footer-subscribe {
        flex-direction: row;
        justify-content: space-between;
    }

    .subscribe-input {
        max-width: 70%; /* Allow more room for the input on smaller screens */
    }

    .subscribe-button {
        flex-shrink: 0;
    }
}

.footer-bottom {
    align-items: center;
    background-color: #111;
    padding: 1rem 0;
    color: #bbb;
    text-align: center;
}

.footer-bottom p {
    margin: 0;
}

.footer-home-link {
    color: #bbb;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-home-link:hover {
    color: #fff;
}

/* Extra small devices (max-width: 576px) */
@media (max-width: 576px) {
    .footer-subscribe {
        flex-direction: row;
        justify-content: center; /* Center the elements */
    }

    .subscribe-input {
        max-width: 70%;
    }

    .subscribe-button {
        margin-left: 10px;
    }
}
