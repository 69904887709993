/* General Styles */
.services-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #f3f8ff;
}

.mainServicesStyle {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}





/* Section Title Styling */
.section-title {
  position: relative;
  padding-bottom: 10px;
  text-align: center;
}

/* Services Subheading */
.text-sm {
  font-size: 1rem;
  font-weight: 500;
  color: #333 !important;
  margin-bottom: 10px;
}

/* Gradient Text for Heading */
.gradient-text {
  background: linear-gradient(90deg, #941BFE, #977EFE); /* Gradient colors */
  -webkit-background-clip: text; /* Apply gradient to text only */
  -webkit-text-fill-color: transparent; /* Make the text transparent */
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
  display: inline-block;
  animation: fadeIn 1.5s ease; /* Optional fade-in effect for the text */
}

/* After Line Under Title */
.section-title::after {
  content: '';
  display: block;
  width: 60px;
  height: 3px;
  background-color: #007bff;
  margin: 10px auto 0;
  border-radius: 5px;
  transition: width 0.3s ease;
}

/* Fade-in Animation for Gradient Text */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Adjust for responsiveness */
@media (max-width: 768px) {
  .section-title::after {
    width: 40px; /* Reduce width for smaller screens */
  }
}

@media (max-width: 480px) {
  .section-title::after {
    width: 30px; /* Further reduce width for very small screens */
  }
}




.section-title {
  text-align: left;
  font-size: 32px;
  grid-column: span 2;
  color: #000;
  margin-bottom: 20px;
}

.section-title p {
  font-size: 14px;
  /* Make paragraph text smaller */
  color: black !important;
  /* Different color for paragraph */
  margin-bottom: 5px;
  /* Add some spacing below the paragraph */
}

.section-title h2 {
  font-size: inherit;
  color: inherit;
  margin-top: 0;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; 
  /* font-family:'Courier New', Courier, monospace;  */
}

.service-card {
  position: relative;
  background-color: #fff;
  padding: 30px 20px;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.8s ease, box-shadow 0.3s ease, background 0.8s ease;
  overflow: hidden;
  border-radius: 10px;
  /* color: #333; */
  
}
.service-card .icon{
  color: #941BFE;
}


.service-card:hover {
  cursor: pointer;
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  background: linear-gradient(90deg, #941bfe, #977efe);
}

.service-card:hover .icon {
  
  color: #fff;
}

.icon {
  background: linear-gradient(90deg, #941bfe, #977efe); /* Apply the gradient here */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;
  transition: color 0.8s ease;
}

.icon img {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
}



/* Rhombus Shape Effect */
.service-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  transform: skew(10deg, 10deg);
  transform-origin: top left;
  z-index: -1;
  border-radius: 10px;
}



/* Card Title */
.service-card h3 {
  font-size: 18px;
  margin: 15px 0;
  color: black !important;
}

.service-card p {
  font-size: 14px;
  /* color: #666 !important; */
}
.service-card:hover p {
  font-size: 14px;
  color: white ;
}



.service-card:hover .read-more {
  border: #fff;
  color: white !important; /* Apply color change */
}





.service-card:hover::before {
  transform: skew(10deg, 10deg) translate(5px, 5px);
}

/* Icon in the Card */
.icon img {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
}

/* Card Title */
.service-card h3 {
  font-size: 18px;
  margin: 15px 0;
  color: #333;
}

.service-card:hover h3 {
  font-size: 18px;
  margin: 15px 0;
  color: white !important;
}

/* Card Description */
.service-card p {
  font-size: 14px;
  color: #666;
}

/* Read More Link with Gradient */
.read-more {
  font-size: 14px;
  background: linear-gradient(90deg, #941bfe, #977efe); /* Gradient for the text */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  display: inline-block;
  margin-top: 10px;
  transition: color 0.3s ease;
}
.read-more:hover {
  text-decoration: underline;
}

/* Responsive Styles */

/* For tablets (2 cards per row) */
@media (max-width: 1024px) {
  .mainServicesStyle {
      grid-template-columns: repeat(2, 1fr);
  }

  .section-title {
      grid-column: span 2;
      /* Title still spans 2 columns */
  }
}

/* For mobile devices (1 card per row) */
@media (max-width: 768px) {
  .mainServicesStyle {
      grid-template-columns: 1fr;
      /* 1 column per row */
  }

  .section-title {
      grid-column: span 1;
      /* Title spans the entire row */
  }
}