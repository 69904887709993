.stats-section {
  padding: 3rem 1rem; /* Add padding to prevent overflow on small devices */
  margin-top: 5%;
  margin-bottom: 5%;
  /* background-color: #ffffff;  */
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  justify-content: center; /* Center-align items on smaller screens */
  gap: 1rem; /* Add spacing between cards */
}

.stat-card {
  flex: 1 1 calc(25% - 1rem); /* 4 cards per row on large screens */
  max-width: 250px; /* Restrict maximum width */
  padding: 1.5rem;
  background: linear-gradient(135deg, #941bfe, #977efe); /* Apply gradient background to the card */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  color: white; /* Set text color to white */
}

.stat-card:hover {
  transform: translateY(-5px);
}

.stat-count {
  font-size: 2rem;
  color: white; /* White color for the count text */
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-weight: bold;
}

.stat-icon {
  font-size: 2rem;
  color: white; /* White color for the icon */
}

.stat-label {
  font-size: 1rem;
  color: white; /* White color for the label text */
  margin-top: 0.5rem;
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .stat-card {
    flex: 1 1 calc(33.33% - 1rem); /* 3 cards per row on tablets */
  }
}

@media (max-width: 768px) {
  .stat-card {
    flex: 1 1 calc(50% - 1rem); /* 2 cards per row on small tablets */
  }
}

@media (max-width: 480px) {
  .stat-card {
    flex: 1 1 calc(100% - 1rem); /* 1 card per row on mobile devices */
  }

  .stat-count {
    font-size: 1.5rem; /* Reduce font size for smaller screens */
  }

  .stat-icon {
    font-size: 1.5rem; /* Reduce icon size for smaller screens */
  }

  .stat-label {
    font-size: 0.9rem; /* Adjust label font size for readability */
  }
}
