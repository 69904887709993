/* FAQ section styles */
.faq-section {
  padding: 4rem 0;
}

/* Title styles */
.faq-title {
  font-weight: bold;
  color: #333;
  margin-bottom: 2rem;
}

.faq-title .highlight {
  color: #ff9800;
}

/* Accordion styles */
.accordion {
  margin-top: 2rem;
}

/* FAQ item styles */
.faq-item {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
}

.faq-question {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  text-align: left;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

.faq-question:hover {
  color: #941BFE;
}

.faq-question.active {
  color: #941BFE;
}

.faq-question .icon {
  font-size: 1rem;
}

/* Answer container */
.faq-answer {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease, padding 0.3s ease;
  background-color: #977EFE;
  margin-top: 0.5rem;
  padding: 0 1rem;
}

.faq-answer.show {
  height: auto;
  padding: 1rem;
}

.faq-answer p {
  margin: 0;
  font-size: 1rem;
  color: white;
}

/* FAQ Image container */
.faq-image {
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-image img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

/* Responsive styles */
@media (min-width: 992px) {
  .faq-title {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  .faq-question {
    font-size: 1.4rem;
  }

  .faq-answer p {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .faq-title {
    font-size: 2rem;
  }

  .faq-question {
    font-size: 1rem;
  }

  .faq-answer p {
    font-size: 0.9rem;
  }

  .faq-image {
    margin-top: 2rem;
  }
}

@media (max-width: 480px) {
  .faq-title {
    font-size: 1.75rem;
  }

  .faq-question {
    font-size: 0.9rem;
  }

  .faq-answer p {
    font-size: 0.85rem;
  }

  .faq-image {
    margin-top: 1.5rem;
  }
}
.gradient-text {
  background: linear-gradient(90deg, #941BFE, #977EFE); /* Gradient colors */
  -webkit-background-clip: text; /* Apply gradient to text only */
  -webkit-text-fill-color: transparent; /* Make the text transparent */
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
  display: inline-block;
  animation: fadeIn 1.5s ease; /* Optional fade-in effect for the text */
}
